import React from 'react'

import styles from './header.module.css'
import { ctaStyleMap, getUrlPrefix } from '../utils/styleMap'

const renderCta = function renderCta(cta, index) {
  const ctaThemeMap = [
    { appearance: 'Primary Button', theme: 'orange'},
    { appearance: 'Secondary Button', theme: 'tealLight' },
    { appearance: 'Secondary Button', theme: 'tealLight' },
    { appearance: 'Secondary Button', theme: 'tealLight' },
    { appearance: 'Secondary Button', theme: 'tealLight' },
    { appearance: 'Secondary Button', theme: 'tealLight' },
  ]
  return (
    <a
      href={cta.url || `${getUrlPrefix(cta)}/${cta.slug}`}
      target={cta.openInNewTab ? "_blank" : "_self"}
      title={cta.title}
      className={`${ctaStyleMap[ctaThemeMap[index].appearance]} ${ctaThemeMap[index].theme}`}
    >
      <span className={'innerText'}>{cta.title}</span>
    </a>
  );
}

const renderDescription = function renderDescription(description) {
  if (description) {
    if (description.childMarkdownRemark) {
      return (<div className={styles.heroDescription}
        dangerouslySetInnerHTML={{
          __html: description.childMarkdownRemark.html,
        }}
      />)
    } else if (typeof description === 'string') {
      return (<p className={styles.heroDescription}>{description}</p>)
    } else {
      return <div className={styles.heroDescription}>{description}</div>
    }
  }
  return '';
}

class Header extends React.Component {
  render () {
    const { backgroundImage, backgroundVideo, title, description, ctas, layout } = this.props;
    return (
      <div className={`${styles.hero} ${layout ? styles[layout] : ''}`}>
        {
          backgroundVideo
            ? (
              <video className={styles.heroVideo} loop autoPlay muted>
                <source src={backgroundVideo.file.url} type={backgroundVideo.file.contentType}></source>
              </video>
            )
            :
            (
              <picture>
                <source srcset={backgroundImage.fluid.srcSetWebp} type="image/webp" />
                <source srcset={backgroundImage.fluid.srcSet} type={backgroundImage.file.contentType} />
                <img className={styles.heroImage} alt={backgroundImage.description} src={backgroundImage.fluid.src} />
              </picture>
            )
        }
        <div className={`${styles.heroDetails} black`}>
          {title && <h1 className={styles.heroHeadline}>{title}</h1>}
          {renderDescription(description)}
          {ctas && (
            <div className={styles.heroCtaWrapper}>
              {ctas.map((cta, i) => renderCta(cta, i))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Header;
